<template>
    <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <b-form-checkbox v-model="addObject.enabled" class="mt-2">Enabled</b-form-checkbox>
            </b-form-group>
            <b-form-group>
                <label for="name">Name:</label>
                <b-form-input
                    id="name"
                    type="text"
                    placeholder="Name"
                    v-model="addObject.name"
                />
            </b-form-group>
            <b-form-group>
                <label for="description">Description:</label>
                <b-form-textarea
                    id="description"
                    type="text"
                    placeholder="Description"
                    v-model="addObject.description"
                />
            </b-form-group>

            <b-form-group>
                <label for="categories">Categories:</label>
                <v-select
                    id="categories"
                    v-model="addObject.selected_categories"
                    :reduce="category => category.id"
                    multiple
                    label="name"
                    :options="categories"
                />

            </b-form-group>

            <b-form-group>
                <label>Image:</label>
                <ImageSelector v-model="addObject.image"/>
            </b-form-group>

        </template>

        <template #modal-footer>

            <b-button variant="danger" @click="addModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="addItem">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BModal, BFormInput, BFormTextarea, BFormGroup, BFormCheckbox} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import ImageSelector from '@/views/components/ImageSelector'

    export default {
        components: {
            ImageSelector,
            BButton,
            BModal,
            BFormInput,
            BFormTextarea,
            BFormGroup,
            BFormCheckbox,
            vSelect
        },
        props: {
            baseItemId: {
                type: String,
                required: true
            },
            categories: {
                type: Array,
                required: true
            }
        },
        data() {
            return {

                addObject: {
                    enabled: true
                },
                addModalActive: false

            }
        },
        methods: {
            open() {
                this.addModalActive = true
            },
            addItem() {
                const thisIns = this
                const loadPromise = this.$http.post(`/api/management/v1/base_item/${  this.baseItemId}/items`, this.addObject)
                loadPromise.then(function(response) {
                    thisIns.$printSuccess('Item added')
                    thisIns.addModalActive = false
                    thisIns.addObject = {}
                    thisIns.$emit('itemAdded', response.data.id)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        }
    }

</script>
