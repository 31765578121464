<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-row>
                    <b-col>
                        <div class="d-flex justify-content-start">
                            <b-button variant="primary" @click="$refs.addItemModal.open()" class="mt-1 mr-2">
                                <feather-icon
                                    icon="PlusIcon"
                                    class="mr-50"
                                />
                                <span class="align-middle">Add</span>
                            </b-button>

                            <b-form-checkbox v-model="openNewItem" class="mt-2">Open added item</b-form-checkbox>
                        </div>
                    </b-col>

                    <b-col>
                        <label for="categories">Categories:</label>
                        <v-select
                            id="categories"
                            v-model="selectedCategories"
                            :reduce="category => category.id"
                            multiple
                            label="name"
                            :options="categories"
                        />
                    </b-col>
                </b-row>

                <basic-table :columns="columns" :data="categoryFilteredData" v-slot="props">
                    <div v-if="props.column.displayType === 2" class="d-flex justify-content-center">

                        <b-button variant="success" @click="$router.push(`/base_item/${  baseItemId}/item/${  props.row.id}`)" class="mr-1">
                            <feather-icon
                                icon="EyeIcon"
                            />
                        </b-button>

                        <b-button variant="danger" @click="removeItem(props.row.id)">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </b-button>
                    </div>
                    <div v-else-if="props.column.displayType === 1" class="d-flex justify-content-center" style="min-width: 150px">
                        <img :src="'/api/management/v1/image/' + props.row.image" style="max-width: 100%; max-height: 100px;" class="rounded-lg" alt="Image"/>
                    </div>
                    <span v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                        <b-badge v-if="props.formattedRow[props.column.field] === 'Yes'" variant="light-success">
                            Yes
                        </b-badge>
                        <b-badge v-else variant="light-danger">
                            No
                        </b-badge>
                    </span>
                    <span v-else-if="props.column.displayType === 4" class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                    <span v-else>
                        <b>{{ props.formattedRow[props.column.field] }}</b>
                        <p style="max-height: 60px;overflow: hidden; text-overflow: ellipsis; word-wrap: break-word; display: block;">
                            {{ props.row.description }}
                        </p>
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <GenericBasicItemAddModal ref="addItemModal" :baseItemId="baseItemId" :categories="categories" v-on:itemAdded="itemAdded"/>

    </div>
</template>
<script>

    import {BOverlay, BCard, BButton, BBadge, BRow, BCol, BFormCheckbox} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import GenericBasicItemAddModal from '@/views/GenericItem/AddModal'
    import vSelect from 'vue-select'

    export default {
        components: {
            GenericBasicItemAddModal,
            BOverlay,
            BCard,
            BButton,
            BasicTable,
            BBadge,
            BRow,
            BCol,
            BFormCheckbox,
            vSelect
        },
        props: {
            baseItemId: {
                type: String,
                required: true
            }
        },
        data() {
            return {

                items: [],
                categories: [],
                dataLoaded: false,

                openNewItem: true,

                selectedCategories: [],

                columns: [
                    {
                        label: 'Image',
                        displayType: 1,
                        field: 'image',
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    {
                        label: 'Enabled',
                        displayType: 3,
                        field(rowObj) {
                            return (rowObj.enabled) ? 'Yes' : 'No'
                        },
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Yes / No'
                        }
                    },
                    {
                        label: 'Categories',
                        displayType: 4,
                        field: this.categoriesRenderer,
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Category'
                        }
                    },
                    {
                        label: 'Fully translated',
                        displayType: 3,
                        field(rowObj) {
                            return (rowObj.fully_translated) ? 'Yes' : 'No'
                        },
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Yes / No'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 2,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]

            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/base_item/${  this.baseItemId}/items`)
                loadPromise.then(function(response) {
                    thisIns.items = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                const categoryPromise = this.$http.get(`/api/management/v1/base_item/${  this.baseItemId}/categories`)
                categoryPromise.then(function(response) {
                    thisIns.categories = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise, categoryPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })

            },
            itemAdded(id) {
                if (this.openNewItem) {
                    this.$router.push(`/base_item/${  this.baseItemId}/item/${  id}`)
                } else {
                    this.loadData()
                }
            },
            removeItem(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/base_item/${  this.baseItemId}/items/${  id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Item removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            categoriesRenderer(rowObj) {

                const categoriesMap = []
                const thisIns = this
                rowObj.selected_categories.forEach(function(id) {
                    if (thisIns.categoriesMap[id]) categoriesMap.push(thisIns.categoriesMap[id].name)
                })
                return categoriesMap.join(', ')

            }

        },
        computed: {
            categoriesMap() {
                if (!this.categories) return {}
                const categories = {}
                this.categories.forEach(function(category) {
                    categories[category.id] = category
                })

                return categories
            },
            categoryFilteredData() {
                if (this.selectedCategories.length === 0) return this.items
                return this.items.filter(item => item.selected_categories.some(category => this.selectedCategories.includes(category)))
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
